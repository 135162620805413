import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Accordion from "../components/accordion"

const supplementations = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Supplementations" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Supplémentations
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          style={{ marginTop: 20, display: "block" }}
          component="span"
        >
          <strong>
            Une prévention par compléments multivitaminés associés à
            une supplémentation en calcium, vitamine D, vitamine B12 et fer, est systématique en cas de chirurgie
            malabsorptive (bypass, dérivation biliopancréatique).
            La prévention par compléments multivitaminés est également nécessaire après une sleeve.
          </strong>
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          style={{ marginTop: 20, marginBottom: 20, display: "block" }}
        >
          Les autres supplémentations s’effectuent au cas par cas en fonction de
          la biologie.
        </Typography>
        <Accordion
          initialList={[]}
          entries={[
            {
              id: "b12",
              header: "Vit B12",
              element: (
                <Typography variant="body1" color="inherit" component="span">
                  <div>
                    <i>Prévention : </i> Vit B12 1000 µg susp buvable : 1 ampoule /semaine 
                    ou Vit B12 350µg : 1 cp /jour
                  </div>
                  <div>
                    <i>En cas d’inobservance ou d’inefficacité : </i> Vit B12
                    1000 µg : 1 injection 1M tous les 1 à 3 mois
                  </div>
                  <div>
                    <i>En cas de carence avérée : </i>Vit B12 1000µg susp
                    buvable : 1 ampoule /jour pendant 2 semaines.
                  </div>
                </Typography>
              ),
            },
            {
              id: "acide",
              header: "Acide folique",
              element: (
                <Typography variant="body1" color="inherit" component="span">
                  <div>
                    <i>Prévention :</i> Apport par multivitamines (0,4 mg/jour)
                  </div>
                  <div>
                    <i>En cas de carence avérée : </i> Acide folique 1 mg /jour
                  </div>
                </Typography>
              ),
            },
            {
              id: "b1",
              header: "Vitamine B1",
              element: (
                <Typography variant="body1" color="inherit" component="span">
                  <div>
                    <i>Prévention :</i> multivitamines
                  </div>
                  <div>
                    <i>Traitement :</i>  Vitamine B1 par voie parentérale en hospitalisation, puis relais per os.
                  </div>
                  <div style={{marginTop: 12}}>
                    <strong>A savoir, après une chirurgie bariatrique :</strong>
                    <div>
                      Tout patient présentant des troubles digestifs (notamment des vomissements) de façon répétée pendant
                      plusieurs jours, même en l'absence de signe neurologique associé, doit être supplémenté en thiamine.
                      Tout patient présentant des troubles digestifs (vomissements, diarrhées…) associés à des signes
                      neurologiques ou psychiatriques même non spécifiques, doit systématiquement être considéré et traité
                      comme ayant un syndrome de Gayet-Wernicke.
                    </div>
                  </div>
                </Typography>
              ),
            },
            {
              id: "vitamine_d",
              header: "Vitamine D (objectif > 30 ng/ml)",
              element: (
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ display: "block" }}
                  component="span"
                >
                  <div>
                    <i>Prévention :</i> Vitamine D3 100 000 UI 1 ampoule tous
                    les 1 à 3 mois, ou Vitamine D3 800 UI /jour.
                  </div>
                  <div>
                    <i>Traitement : </i> Vitamine D3 100 000 UI en cas de
                    25OH-D3 {"<"} 30 ng/L :
                    <ul style={{ marginTop: 2 }}>
                      <li>
                        20-30 ng/ml : 1 ampoule tous les 15 jours pendant 1 mois
                      </li>
                      <li>
                        10-20 ng/ml : 1 ampoule tous les 15 jours pendant 6
                        semaines
                      </li>
                      <li>
                        {"<"} 10 ng/ml : 1 ampoule tous les 15 jours pendant 2
                        mois
                      </li>
                    </ul>
                  </div>
                </Typography>
              ),
            },
            {
              id: "calcium",
              header: "Calcium",
              element: (
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ display: "block" }}
                >
                  <i>Prévention/Traitement :</i> 1000 à 1500 mg/jour en plusieurs prises (supplémentation sous forme de citrate de calcium de préférence).
                </Typography>
              ),
            },
            {
              id: "fer",
              header: "Fer",
              element: (
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ display: "block" }}
                  component="span"
                >
                  <div>
                    <i>Prévention/Traitement :</i> voie orale, dose de 60 à 200
                    mg de fer par jour.
                  </div>
                  <div>
                    Possibilité d’associer de la vitamine C afin de faciliter
                    l’absorption du fer.
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <i>
                      En cas d’échec et/ou d’intolérance de la supplémentation
                      orale, ou en cas d’anémie {"<"}10 g/dL avec une ferritine
                      {"<"}30 µg/L :{" "}
                    </i>
                    une supplémentation IV est justifiée (maximum 1g de fer par perfusion).
                  </div>
                </Typography>
              ),
            },
            {
              id: "Zinc",
              header: "Zinc",
              element: (
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ display: "block" }}
                >
                  <div style={{marginBottom: 12}}>
                    En cas de déficit en zinc, la supplémentation doit être associée à du cuivre (1mg de cuivre pour 10mg
                    de zinc). La prise de zinc doit se faire à distance des autres supplémentations (notamment du fer).
                  </div>
                  <div>
                    <i>Prévention :</i> multivitamines
                  </div>
                  <div>
                    <i>En cas de carence avérée :</i> gluconate de zinc 15 à 30 mg/ jour
                  </div>
                </Typography>
              ),
            },
            {
              id: "Protéines",
              header: "Protéines",
              element: (
                <Typography
                  variant="body1"
                  color="inherit"
                  style={{ display: "block" }}
                  component="span"
                >
                  <div>
                    Les apports recommandés sont de 60 grammes par jour au minimum, et
                    idéalement au moins 1,1g par kg de poids idéal par jour.
                  </div>
                  <div style={{ marginTop: 8 }}>
                    Si l’objectif de 60g de protéines par jour n’est pas
                    atteint, le recours à des compléments nutritionnels oraux à
                    faible index glycémique peut être utile.
                  </div>
                </Typography>
              ),
            },
          ]}
        />
        <Typography
          variant="body1"
          color="inherit"
          style={{ 
            marginTop: 20,
            marginBottom: 20,
            display: "block",
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <a 
            target="_blank"
            href="https://www.sfncm.org/images/stories/pdf_pratiquesnutrition/PRev_TTT_Carence_Chir%20Obes.pdf">
            https://www.sfncm.org/images/stories/pdf_pratiquesnutrition/PRev_TTT_Carence_Chir%20Obes.pdf
          </a>
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default supplementations
